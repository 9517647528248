<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="content-share" slot="component">
      <div>
        <p class="content-share-link-title">{{ $t("share this link") }}</p>
        <!-- <p
          class="content-share-content-title"
        >{{ $t('check out')}} "{{ content.title }}" {{$t('on')}} {{ projectName }}!</p> -->
        <p class="content-share-content-title">{{ $t("Watch") }} "{{ content.title }}" {{ $t("on Noorplay") }}!</p>
        <div class="content-share-input" dir="ltr">
          <input
            class="content-share-input-link-field"
            id="content-share-link"
            type="text"
            @keydown.prevent
            v-model="link"
            ref="link"
          />
          <button
            class="content-share-input-btn-copy copy-btn"
            :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
            :data-clipboard-text="link"
          >
            {{ $t("copy") }}
          </button>
        </div>
      </div>
      <!-- <p class="content-share-title">Share to</p> -->
      <div>
        <ul class="content-share-list">
          <li>
            <a target="_blank" :href="facebookShareUrl">
              <img src="@/assets/icons/newfb.svg" class="share-ico" alt="fb icon" @click="shareThroughFacebook" />
            </a>
          </li>
          <li>
            <a target="_blank" :href="twitterShareUrl" id="twitter-button">
              <img src="@/assets/icons/newtwitter.svg" @click="twitterShare()" class="share-ico" alt="gp icon" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </Popup>
</template>

<script>
import snackbar from "node-snackbar";
import "../../../node_modules/node-snackbar/dist/snackbar.css";
import ClipboardJS from "clipboard";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import common from "@/mixins/common.js";
import { mapGetters } from "vuex";
import { _providerId, _projectName } from "@/provider-config.js";
import { eventBus } from "@/eventBus";

import { showSnackBar } from '@/utilities';

export default {
  props: {
    closePopup: {
      type: Function,
    },
    content: {
      type: Object,
    },
  },
  data() {
    return {
      width: "40%",
      margin: "15% auto",
      radiusType: "full",
      medium: null,
      link: null,
      twitterlink: null,
      facebooklink: null,
      deeplink: null,
      clipboard: undefined,
      twitterShareUrl: null,
      projectName: null,
      filteredAvailabilities: [],
      pricemodel: null,
      availabilities: [],
      providerUniqueId: _providerId,
      facebookShareUrl: null,
      localDisplayLang: ''
    };
  },
  watch: {
    twitterlink(val) {
      if (val) {
        this.sendToTwitter();
      }
    },
    facebooklink(val) {
      if (val) {
        this.sendToFacebook();
      }
    },
  },
  computed: {
    ...mapGetters(["availabilityList"]),
  },
  created() {
     this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
    //assigning availability list response.
    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    this.projectName = _projectName;

    // this.createBranchLink();
    this.createLink();

    this.initTwitterUrl();

    this.shareThroughFacebook();
    window.dispatchEvent(window.facebookLoaded);

    this.checkAvailabilityForClevertap(this.content);
  },
  mounted() {
    setTimeout(() => {
      this.$refs.link.disabled = true;

      let dom = document.getElementById("popup-close-icon");
      dom.style.opacity = 1;
    }, 100);
  },
  methods: {
    createBranchLink(medium) {
      console.log('what is the route in share content',this.$route)
      let self = this;

      var linkData = {
        // branch_key: "key_live_ndGW8YRJAehKig50HTghTcooDsdLBpj3",
        campaign: "content",
        channel: medium,
        stage: "new user",
        alias: "",
        data: {
          custom_bool: true,
          custom_int: Date.now(),
          custom_string: "Deep Link",
          $canonical_identifier: this.content.objectid,
          $og_title: this.content.title,
          $og_description: this.content.shortdescription,
          $og_image_url: this.getPoster(this.content),
          $desktop_url: "https://noorplay.com",
          // $desktop_url: "https://netfive-web.web.app",
          // $desktop_url: "https://net5.in",
          $ios_deeplink_path: `net5://content/details/${this.content.objectid}`,
          $android_deeplink_path: `net5://content/details/${this.content.objectid}`,
          $desktop_deeplink_path: `${window.location.origin}/content/details/${this.content.objectid}`,
        },
      };

      branch.link(linkData, function(err, link) {
        console.log("linkData-------", link);
          let item = self.content;
          let mediaType = "";
          let title;
          if (item.category == "TVSHOW"){
            if (self.localDisplayLang == "eng"){
                title = item.title.replace(/\s/g, '-')
              } else {
                title = "title"
              }
              mediaType = "/series/"
          } else {
             if (self.localDisplayLang == "eng"){
                title = item.title.replace(/\s/g, '-')
              } else {
                title = "title"
              }
            mediaType = "/movie/"
          }

        let urlData = mediaType + title + "/"+ item.objectid

        link = location.origin +`${urlData}`;
        if (medium === "sharing") {
          self.link = link;
        } else if (medium === "twitter") {
          self.twitterlink = link;
        } else if (medium === "facebook") {
          self.facebooklink = link;
        }

        // self.createLink();
        // self.initTwitterUrl();
      });
    },
    shareThroughFacebook() {
      // Get the URL to be shared
      this.createBranchLink("facebook");
    },
    sendToFacebook() {
      console.log("facebook link", this.facebooklink);
      this.facebookShareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + decodeURIComponent(this.facebooklink);
      // let link = this.facebooklink;
      // // Get the image to be shown on the feed
      // let image = this.getPoster(this.content);
      // // Get the title to be shown on the feed
      // let title = `Watch ${this.content.title} on Vlive`;
      // // Get the description to be shown on the feed
      // let description = this.content.shortdescription;

      // // Initialize Share
      // window.FB.ui(
      //   {
      //     method: "share_open_graph",
      //     action_type: "og.shares",
      //     action_properties: JSON.stringify({
      //       object: {
      //         "og:url": link,
      //         "og:title": title,
      //         "og:description": description,
      //         "og:image": image
      //       }
      //     })
      //   },
      //   response => {
      //     console.log("[shareFbCB response]", response);

      //     //global share event
      //     this.shareEvent("Facebook", this.content);

      //     //social share event
      //     this.socialShareEvent("Facebook");
      //   }
      // );
    },
    twitterShare() {
      //global share event
      this.shareEvent("Twitter", this.content);

      //social share event
      this.socialShareEvent("Twitter");
    },

    initTwitterUrl() {
      this.createBranchLink("twitter");
    },
    sendToTwitter() {
      console.log("twitter link", this.twitterlink);
      let sharedTwitter = `${window.location.href}${this.content.objectid}`;
      this.twitterShareUrl = "https://twitter.com/share?url=" + decodeURIComponent(this.twitterlink);
      // this.twitterShareUrl = this.link;
      // console.log("url>>", this.twitterShareUrl);
    },
    createLink() {
      this.createBranchLink("sharing");
      let self = this;

      if (this.clipboard) {
        this.clipboard.destroy();
      }

      // this.deeplink = `${window.location.host}/content?contentid=${this.content.objectid}`;

      this.clipboard = new ClipboardJS(".copy-btn");
      this.clipboard.on("success", function(e) {
        //global share event
        self.shareEvent("Deep_Link", self.content);
       

        // Show the snackbar message
        // snackbar.show({ text: "Link Copied", pos: "bottom-center" });

        showSnackBar(this.$t("Link Copied"));
        e.clearSelection();
      });
      this.clipboard.on("error", function(e) {
        console.error("Action:", e.action);
        console.error("Trigger:", e.trigger);
      });
    },
    getPoster(content) {
      if (content.poster && content.poster.length) {
        let index = this.content.poster.findIndex((element) => {
          if (element.quality === "HD") {
            return element.postertype === "LANDSCAPE" && element.quality === "HD";
          }
        });

        if (index > -1) {
          return this.content.poster[index].filelist[2].filename;
        }
      }
    },
    shareEvent(medium, content) {
      let data = {
        content_id: content.objectid,
        content_title: content.title,
        content_type: content.objecttype,
        content_genre: content.genre,
        content_language: content.contentlanguage[0],
        content_duration: content.duration,
        content_price: this.pricemodel === "FREE" ? "Free" : "Paid",
        medium: medium,
      };

      //clevertap event
      this.sharedCleverEvent(data);

      //google analytics event
      this.sharedEvent(data);
    },

    socialShareEvent(medium) {
      let data = {
        Medium: medium,
      };

      //clevertap event
      this.referralCleverTapEvent(data);

      //google analytics event
      this.referralEvent(data);
    },
  },
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
  },
  mixins: [googleAnalytics, cleverTap, common],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.content-share {
  background: $clr-dark-gd4;
  border-radius: 0.6rem;
  padding: 3rem;
  &-link-title {
    font-family: $font-regular;
    font-size: 0.9rem;
    font-weight: 600;
    color: $clr-light-gd2;
    margin-bottom: 10px;
  }
  &-content-title {
    font-family: $font-regular;
    font-size: 0.8rem;
    color: grey;
  }
  &-input {
    position: relative;
    &-link-field {
      border: 1px solid $clr-light-gd4;
      border-radius: 5px;
      color: $clr-light-gd4;
      padding-right: 20%;
      &:focus {
        border: 1px solid $clr-light-gd4;
        border-radius: 5px;
        color: $clr-light-gd4;
        background: transparent;
      }
    }
    &-btn-copy {
      position: absolute;
      top: 13px;
      right: 13px;
      background-color: $btn-clr;
      padding: 5px 15px;
      color: $clr-dark-gd3;
      border: none;
      border-radius: 5px;
      font-family: $font-regular;
      font-weight: bold;
      cursor: pointer;
    }
  }
  &-title {
    color: $clr-light-gd2;
    margin: 10px 0;
    font-family: $font-regular;
  }
  &-list {
    display: flex;
    height: 45px;
    li {
      margin-right: 15px;
      border-radius: 5px;
      cursor: pointer;
      &:last-child {
        margin-top: 3px;
      }
      img {
        width: 45px;
        // border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: 10px;
      }
      &:hover {
        box-shadow: 0px 0px 5px 0px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .content-share {
    padding: 2rem;
    &-input {
      &-link-field {
        padding-right: 35%;
      }
    }
  }
}
</style>
